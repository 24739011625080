import React from "react";

import "./CvView.css"
import CvData from "../__contrib/cv.json"
import CvItem from "../Components/CV/CvItem";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";
import {HashRouter as Router} from "react-router-dom";



function CvView() {
  const [work, setWork] = React.useState(CvData.work);
  const [school, setSchool] = React.useState(CvData.education);
  return (
      <div>
        <Header/>
        <div className={"cvContainer"}>
          <h3>Work Experience</h3>
          <ul className={"cvList"}>
            {work.map((item) => <li className={"cvListItem"}><CvItem title={item.title} date={item.date} content={item.content}/></li>)}
          </ul>
          <h3>Education</h3>
          <ul className={"cvList"}>
            {school.map((item) => <li className={"cvListItem"}><CvItem title={item.title} date={item.date} content={item.content}/></li>)}
          </ul>
        </div>
        <Footer/>
      </div>
  )
}

export default CvView
