import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

function Footer(props) {
  var footerClass = "footer";
  if(window.location.href.endsWith("/#/")){
    footerClass = "footer d-md-flex d-none"
  }
  return (
      <Navbar bg="light" expand="lg" className={footerClass}>
        <div className={"horizontalCenter fitContent"}>
          <Row>
            <Col>
              <div className={"horizontalCenter fitContent"}>
                <a href={"#Disclaimer"}>Disclaimer</a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              © Maximilian Henneberg 2020
            </Col>
          </Row>
        </div>
      </Navbar>
  )
}

export default Footer;
