import React from "react";

function DisclaimerItem(props) {
  return(
      <div>
        <h5>{props.title}</h5>
        <div dangerouslySetInnerHTML={{__html:props.text}}/>
      </div>
  )
}

export default DisclaimerItem;
