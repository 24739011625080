import React from "react";

import DisclaimerData from "../__contrib/disclaimer";
import DisclaimerItem from "../Components/DisclaimerItem/DisclaimerItem";
import "./DisclaimerView.css"
import Header from "../Components/Header/Header";
import {HashRouter as Router} from "react-router-dom";
import Footer from "../Components/Footer/Footer";

function DisclaimerView(props) {
  return (
      <div>
        <Header/>
        <div className={"container"}>
          <DisclaimerItem title={DisclaimerData.icons.title} text={DisclaimerData.icons.text}/>
          <hr/>
          <DisclaimerItem title={DisclaimerData.urheberrecht.title} text={DisclaimerData.urheberrecht.text}/>
          <hr/>
          <DisclaimerItem title={DisclaimerData.haftung.title} text={DisclaimerData.haftung.text}/>
          <hr/>
          <DisclaimerItem title={DisclaimerData.linkHaftung.title} text={DisclaimerData.linkHaftung.text}/>
          <hr/>
          <DisclaimerItem title={DisclaimerData.datenschutz.title} text={DisclaimerData.datenschutz.text}/>
        </div>
        <Footer/>
      </div>
  );
}

export default DisclaimerView;
