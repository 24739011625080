import React from "react";
import CallingCard from "../Components/CallingCard/CallingCardContainer";
import FloatingBurgerMenue from "../Components/FloatingBurgerMenue/FlaotingBurgerMenue";
import Header from "../Components/Header/Header";
import Footer from "../Components/Footer/Footer";

function HomeView() {
  return (
      <div>
        <Header/>
        <FloatingBurgerMenue/>
        <CallingCard/>
        <Footer/>
      </div>

  )
}

export default HomeView;
