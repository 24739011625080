import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import "./CallingCard.css";
import CallingCardFront from "./CallingCardFront";
import CallingCardBack from "./CallingCardBack";

function CallingCardContainer() {
  const rotate0to90 = "rotate0to90";
  const rotate0to90Mobile = "rotate0to90Mobile";
  const rotate90to180 = "rotate90to180";
  const rotate90to180Mobile = "rotate90to180Mobile";
  const rotate180to90 = "rotate180to90";
  const rotate180to90Mobile = "rotate180to90Mobile";
  const rotate90to0 = "rotate90to0";
  const rotate90to0Mobile = "rotate90to0Mobile";
  const [rotated, setRotated] = React.useState(false);
  const [animationClass, setAnimationClass] = React.useState("");
  return (
      <div className={"callingCardContainer "+animationClass} onAnimationEnd={(e) => {
        if (e.animationName === rotate0to90 || e.animationName === rotate0to90Mobile) {
          setRotated(!rotated);
          setAnimationClass(rotate90to180);
        }
        if (e.animationName === rotate90to180 || e.animationName === rotate90to180Mobile) {
          setAnimationClass("backfront");
        }
        if(e.animationName === rotate180to90 || e.animationName === rotate180to90Mobile){
          setRotated(!rotated);
          setAnimationClass(rotate90to0);
        }
        if (e.animationName === rotate90to0 || e.animationName === rotate90to0Mobile) {
          setAnimationClass("");
        }
      }}>
        {!rotated &&
        <CallingCardFront clickCallback={(e) => setAnimationClass(rotate0to90)}/>
        }

        {rotated &&
        <div className={"backfront"}>
        <CallingCardBack clickCallback={(e) => setAnimationClass(rotate180to90)}/>
        </div>}

      </div>
  )
}

export default CallingCardContainer;
