import React from "react";
import "./CV.css"

function CvItem(props) {
  return (
      <div className={"cvItemContainer"}>
        <div className={"top"}>
          <h4 className="float-md-left">{props.title}</h4>
          <h4 className="float-md-right">{props.date}</h4>
        </div>
        <hr/>
        <div dangerouslySetInnerHTML={{__html:props.content}}/>
      </div>
  )
}

export default CvItem;
