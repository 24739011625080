import React from "react";

import "./FloatingBurgerMenue.css"
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";

function FloatingBurgerMenue(props) {
  return (
      <div className="floatingButton d-box d-md-none">
        <DropdownButton drop="left">
          <Dropdown.Item className="rotatedDropDownItem" eventKey="1" href="#CV">CV</Dropdown.Item>
        </DropdownButton>

      </div>
  )
}

export default FloatingBurgerMenue;
